import { PeriodicReportHistoryItemDto } from 'api/models/PeriodicReportHistoryItemDto';
import { FIELDS } from '../periodicReportsHistoryList.types';

export const createRows = (periodicReportHistoryItemDto?: PeriodicReportHistoryItemDto[]) => {
  if (!periodicReportHistoryItemDto) return [];

  return periodicReportHistoryItemDto.map((result: PeriodicReportHistoryItemDto) => ({
    [FIELDS.ID]: result.reportId,
    [FIELDS.REPORT_NAME]: result.name,
    [FIELDS.GENERATED_AT]: result.generatedAt,
    [FIELDS.REPORT_OWNER]:
      (result.reportOwner?.firstName ? result.reportOwner?.firstName : '') +
      ' ' +
      (result.reportOwner?.lastName ? result.reportOwner.lastName : ''),
    [FIELDS.FREQUENCY]: result.frequency
  }));
};
