import { Breadcrumbs, Paper, Header, Scrollbar, Pagination } from 'components';
import { CircularProgress, TableBody, TableCell, TableContainer, TableRow, SelectChangeEvent } from '@mui/material';
import tableClasses from '../../../styles/table.module.scss';
import Table from '@mui/material/Table';
import SideNav from 'components/SideNav';
import classes from './periodicReportsHistoryList.module.scss';
import sideNavClasses from '../../../App.module.scss';
import { Download, TableHead } from './components';
import { HeadCell, Order, mapSortDirection } from 'types';
import { FIELDS, GetPeriodicReportHistoryQueryResult } from './periodicReportsHistoryList.types';
import { useGoToFirstPage, useRowsPerPage, useSendRequest } from 'lib/hooks';
import { useEffect, useState, MouseEvent } from 'react';
import { PATH } from 'router/routes';
import { createRows } from './utils';
import moment from 'moment';
import reportFrequency from 'lib/utils/reportFrequency';
import { useAuth } from 'oidc-react';
import { ROLE } from 'config/oidc';
import { useSearchParams } from 'react-router-dom';
import { getPeriodicReportsHistory } from 'api/services/periodicReportsHistory';

const headerTooltip =
  '"Historia raportów cyklicznych" zawiera wszystkie wygenerowane raporty cykliczne, które zostały uprzednio skonfigurowane w "Kreatorze raportów cyklicznych". Raporty te są widoczne dla wszystkich aktywnych użytkowników w ramach Twojej firmy. Raport cykliczny jest usuwany z tej listy po 2 miesiącach od daty jego wygenerowania.';

const PeriodicReportsHistoryList = () => {
  const localStorageOrderName = 'periodicReportsHistoryOrder';
  const localStorageOrderByName = 'periodicReportsHistoryOrderBy';
  const savedOrder = localStorage.getItem(localStorageOrderName);
  const savedOrderBy = localStorage.getItem(localStorageOrderByName);

  const breadcrumbs = [{ label: 'Historia raportów cyklicznych' }];
  const rowsPerPage = useRowsPerPage();
  const { goToFirstPage } = useGoToFirstPage();
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [order, setOrder] = useState<Order>((savedOrder as Order) ?? 'asc');
  const [orderBy, setOrderBy] = useState<FIELDS>((savedOrderBy as FIELDS) ?? FIELDS.REPORT_NAME);
  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const userRole = auth?.userData?.profile?.Role;
  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;
  const pageNumber = searchParams.get('page');

  const tableCellStyles = { padding: '1.5rem', whiteSpace: 'nowrap' };

  const { result: getPeriodicReportHistoryQueryResult, isLoading: isLoadingExportData, sendData } = useSendRequest();

  useEffect(() => {
    const query = `CompanyId=${companyId}&PageNumber=${pageNumber}&PageSize=${pageSize}&OrderByColumn=${orderBy}&OrderByAscending=${mapSortDirection(
      order
    )}`;
    sendData(() => getPeriodicReportsHistory(query));
  }, [pageNumber, pageSize, orderBy, order]);

  const rows = createRows((getPeriodicReportHistoryQueryResult as GetPeriodicReportHistoryQueryResult)?.results);

  const handleRequestSort = (event: MouseEvent<unknown>, property: FIELDS) => {
    const isAsc = orderBy === property && order === 'asc';
    const orderValue = isAsc ? 'desc' : 'asc';
    setOrder(orderValue);
    setOrderBy(property);
    localStorage.setItem(localStorageOrderName, orderValue);
    localStorage.setItem(localStorageOrderByName, property);
  };

  const headCells: HeadCell<FIELDS>[] = [
    {
      id: FIELDS.REPORT_NAME,
      numeric: false,
      disablePadding: true,
      label: 'Nazwa raportu',
      sortable: true
    },
    {
      id: FIELDS.GENERATED_AT,
      numeric: false,
      disablePadding: true,
      label: 'Data wygenerowania',
      sortable: true
    },
    {
      id: FIELDS.REPORT_OWNER,
      numeric: false,
      disablePadding: true,
      label: 'Właściciel raportu',
      sortable: true
    },
    {
      id: FIELDS.FREQUENCY,
      numeric: true,
      disablePadding: true,
      label: 'Częstotliwość wysyłania raportu',
      sortable: true
    },
    {
      id: FIELDS.ID,
      numeric: false,
      disablePadding: true,
      label: 'Pobierz',
      sortable: false
    }
  ];

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
    goToFirstPage(PATH.PERIODIC_REPORTS_HISTORY);
  };

  return (
    <div className={classes.wrapper}>
      <div className={sideNavClasses.sidebar}>
        <SideNav />
      </div>
      <div className={classes.innerWrapper}>
        <Paper overflow fullSize>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <div>
            <Header
              title={<span className={tableClasses.header}>Historia raportów cyklicznych</span>}
              tooltipText={headerTooltip}
              justifyContent="space-between"
            />
          </div>
          {isLoadingExportData ? (
            <div className={classes.spinnerWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <TableContainer>
                <Scrollbar>
                  <Table className={tableClasses.table} aria-labelledby="tableTitle" size="medium">
                    <TableHead
                      headCells={headCells}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {rows.map((row: any) => {
                        return (
                          <TableRow key={row.id} hover tabIndex={-1}>
                            <TableCell sx={tableCellStyles}>{row.name}</TableCell>
                            <TableCell sx={tableCellStyles}>
                              {row.generatedAt ? moment(row.generatedAt).format('DD-MM-YYYY HH:mm:ss') : ''}
                            </TableCell>
                            <TableCell sx={tableCellStyles}>{row.firstName}</TableCell>
                            <TableCell sx={tableCellStyles}>{reportFrequency(row.frequency)}</TableCell>
                            <TableCell sx={tableCellStyles}>
                              <Download download={row.id} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <Pagination
                extraData={`Liczba rekordów: ${
                  (getPeriodicReportHistoryQueryResult as GetPeriodicReportHistoryQueryResult)?.totalSize
                }`}
                total={(getPeriodicReportHistoryQueryResult as GetPeriodicReportHistoryQueryResult)?.totalSize || 1}
                pageSize={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default PeriodicReportsHistoryList;
