import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Breadcrumbs, Loader } from 'components';
import { PATH } from 'router/routes';
import { updateReport, getReport } from 'api/services';
import { useFetch } from 'lib/hooks';
import { FormikSubmitValues, ReportForm } from '../shared';
import { Box } from '@mui/material';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import {
  ReportRecipientItemDto,
  ReportRiskGroupItemDto,
  TradeCreditLimitDefinitionState,
  TradeCreditLimitRangeDto,
  DebtDefinitionState,
  DebtDefinitionDeclineThresholdDto,
  DebtRangeDto,
  RiskGroupDefinitionState
} from 'api/models';
import {
  allChangesOptionValue,
  noDebtOptionValue,
  tradeCreditLimitRanges,
  debtRanges,
  tradeCreditLimitDeclineThresholdsDefaultOptionValue,
  debtImprovementTresholdDefaultOptionValue
} from '../shared/components/ReportForm';

export enum FIELDS {
  COMPANY_ID = 'companyId',
  REPORT_ID = 'reportId',
  NAME = 'name',
  FREQUENCY = 'frequency',
  RECIPIENTS = 'recipients',
  DETAILED_COUNTERPARTY_LIST = 'detailedCounterpartyList',
  REPORT_SHARING_METHOD = 'reportSharingMethod',

  RISK_GROUP_DEFINITION_STATE = 'riskGroupDefinitionState',
  RISK_GROUP_USE_IMPROVEMENT_FILTER = 'riskGroupUseImprovementFilter',
  RISK_GROUP_IMPROVEMENT_THRESHOLD = 'riskGroupImprovementThreshold',
  RISK_GROUP_USE_DECLINE_FILTER = 'riskGroupUseDeclineFilter',
  RISK_GROUP_DECLINE_THRESHOLD = 'riskGroupDeclineThreshold',
  RISK_GROUPS = 'riskGroups',

  TRADE_CREDIT_LIMIT_DEFINITION_STATE = 'tradeCreditLimitDefinitionState',
  TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD = 'tradeCreditLimitUseImprovementThreshold',
  TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD = 'tradeCreditLimitImprovementThreshold',
  TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD = 'tradeCreditLimitUseDeclineThreshold',
  TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD = 'tradeCreditLimitDeclineThreshold',
  TRADE_CREDIT_LIMIT_USE_RANGE_FILTER = 'tradeCreditLimitUseRangeFilter',
  TRADE_CREDIT_LIMIT_RANGE_FILTER = 'tradeCreditLimitRangeFilter',

  DEBT_DEFINITION_STATE = 'debtDefinitionState',
  DEBT_USE_IMPROVEMENT_THRESHOLD = 'debtUseImprovementThreshold',
  DEBT_IMPROVEMENT_THRESHOLD = 'debtImprovementThreshold',
  DEBT_USE_DECLINE_THRESHOLD = 'debtUseDeclineThreshold',
  DEBT_DECLINE_THRESHOLD = 'debtDeclineThreshold',
  DEBT_ADDING_DEBTOR = 'debtAddingDebtor',
  DEBT_REMOVING_DEBTOR = 'debtRemovingDebtor',
  DEBT_RANGE_FILTER = 'debtRangeFilter'
}

export type FormikValues = {
  [FIELDS.COMPANY_ID]: number;
  [FIELDS.REPORT_ID]: number;
  [FIELDS.NAME]: string;
  [FIELDS.FREQUENCY]: number;
  [FIELDS.RECIPIENTS]: number[];
  [FIELDS.DETAILED_COUNTERPARTY_LIST]: number;
  [FIELDS.REPORT_SHARING_METHOD]: number;

  [FIELDS.RISK_GROUP_DEFINITION_STATE]: number;
  [FIELDS.RISK_GROUP_USE_IMPROVEMENT_FILTER]: boolean;
  [FIELDS.RISK_GROUP_IMPROVEMENT_THRESHOLD]: string;
  [FIELDS.RISK_GROUP_USE_DECLINE_FILTER]: boolean;
  [FIELDS.RISK_GROUP_DECLINE_THRESHOLD]: string;
  [FIELDS.RISK_GROUPS]: number[];

  [FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]: TradeCreditLimitDefinitionState;
  [FIELDS.TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD]: boolean;
  [FIELDS.TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD]: string;
  [FIELDS.TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD]: boolean;
  [FIELDS.TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD]: string;
  [FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER]: TradeCreditLimitRangeDto[];

  [FIELDS.DEBT_DEFINITION_STATE]: DebtDefinitionState;
  [FIELDS.DEBT_USE_IMPROVEMENT_THRESHOLD]: boolean;
  [FIELDS.DEBT_IMPROVEMENT_THRESHOLD]: string;
  [FIELDS.DEBT_USE_DECLINE_THRESHOLD]: boolean;
  [FIELDS.DEBT_DECLINE_THRESHOLD]: string;
  [FIELDS.DEBT_ADDING_DEBTOR]: boolean;
  [FIELDS.DEBT_REMOVING_DEBTOR]: boolean;
  [FIELDS.DEBT_RANGE_FILTER]: DebtRangeDto[];
};

const EditReport = () => {
  const { id } = useParams();

  const { result, isLoading } = useFetch<any>(useCallback(() => getReport(id || ''), [id]));
  const breadcrumbs = [{ to: PATH.REPORTS_LIST, label: 'Kreator raportów' }];

  const mapDebtDeclineThresholdDtoToOptionValue = (
    declineThreshold: DebtDefinitionDeclineThresholdDto | null
  ): string => {
    if (declineThreshold === null) {
      return allChangesOptionValue;
    } else if (declineThreshold?.isDefined === false) {
      return noDebtOptionValue;
    } else {
      return declineThreshold?.threshold?.toString() ?? allChangesOptionValue;
    }
  };

  const valuesForForm: FormikValues = {
    [FIELDS.COMPANY_ID]: result?.companyId || 0,
    [FIELDS.REPORT_ID]: result?.reportId || 0,
    [FIELDS.NAME]: result?.name || '',
    [FIELDS.FREQUENCY]: result?.frequency || 0,
    [FIELDS.RECIPIENTS]: result?.recipients.map((recipient: ReportRecipientItemDto) => recipient.externalId) || [],
    [FIELDS.DETAILED_COUNTERPARTY_LIST]: result?.detailedCounterpartyList || 0,
    [FIELDS.REPORT_SHARING_METHOD]: result?.reportSharingMethod || 0,

    [FIELDS.RISK_GROUP_DEFINITION_STATE]: result?.riskGroupDefinition.riskGroupDefinitionState || 0,
    [FIELDS.RISK_GROUP_USE_IMPROVEMENT_FILTER]: result?.riskGroupDefinition.useImprovementFilter || false,
    [FIELDS.RISK_GROUP_IMPROVEMENT_THRESHOLD]:
      result?.riskGroupDefinition.improvementThreshold?.externalId ?? allChangesOptionValue,
    [FIELDS.RISK_GROUP_USE_DECLINE_FILTER]:
      result?.riskGroupDefinition.useDeclineFilter ||
      result?.riskGroupDefinition.riskGroupDefinitionState !== RiskGroupDefinitionState.CustomDefinition ||
      false,
    [FIELDS.RISK_GROUP_DECLINE_THRESHOLD]:
      result?.riskGroupDefinition.declineThreshold?.externalId ?? allChangesOptionValue,
    [FIELDS.RISK_GROUPS]:
      result?.riskGroupDefinition.riskGroups.map((riskGroup: ReportRiskGroupItemDto) => riskGroup.externalId) || [],

    [FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]: result?.tradeCreditLimitFilter?.tradeCreditLimitDefinitionState || 0,
    [FIELDS.TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD]:
      result?.tradeCreditLimitFilter?.useImprovementThreshold || false,
    [FIELDS.TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD]:
      result?.tradeCreditLimitFilter?.improvementThreshold ?? allChangesOptionValue,
    [FIELDS.TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD]:
      result?.tradeCreditLimitFilter?.useDeclineThreshold ||
      result?.tradeCreditLimitFilter?.tradeCreditLimitDefinitionState !==
        TradeCreditLimitDefinitionState.CustomDefinition ||
      false,
    [FIELDS.TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD]:
      result?.tradeCreditLimitFilter?.declineThreshold ?? tradeCreditLimitDeclineThresholdsDefaultOptionValue,
    [FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER]:
      result?.tradeCreditLimitFilter?.rangeFilter.map((valueFromApi: TradeCreditLimitRangeDto) => {
        return tradeCreditLimitRanges.find(
          (valueFromConst) => JSON.stringify(valueFromApi) === JSON.stringify(valueFromConst)
        );
      }) ?? [],
    [FIELDS.DEBT_DEFINITION_STATE]: result?.debtReportDefinition?.debtDefinitionState || 0,
    [FIELDS.DEBT_USE_IMPROVEMENT_THRESHOLD]:
      result?.debtReportDefinition?.useImprovementThreshold ||
      result?.debtReportDefinition?.debtDefinitionState !== DebtDefinitionState.CustomDefinition ||
      false,
    [FIELDS.DEBT_IMPROVEMENT_THRESHOLD]:
      result?.debtReportDefinition?.improvementThreshold ?? debtImprovementTresholdDefaultOptionValue,
    [FIELDS.DEBT_USE_DECLINE_THRESHOLD]: result?.debtReportDefinition?.useDeclineThreshold || false,
    [FIELDS.DEBT_DECLINE_THRESHOLD]: mapDebtDeclineThresholdDtoToOptionValue(
      result?.debtReportDefinition?.declineThreshold
    ),
    [FIELDS.DEBT_ADDING_DEBTOR]:
      result?.debtReportDefinition?.addingDebtor ||
      result?.debtReportDefinition?.debtDefinitionState !== DebtDefinitionState.CustomDefinition ||
      false,
    [FIELDS.DEBT_REMOVING_DEBTOR]:
      result?.debtReportDefinition?.removingDebtor ||
      result?.debtReportDefinition?.debtDefinitionState !== DebtDefinitionState.CustomDefinition ||
      false,
    [FIELDS.DEBT_RANGE_FILTER]:
      result?.debtReportDefinition?.rangeFilter.map((valueFromApi: DebtRangeDto) => {
        return debtRanges.find((valueFromConst) => JSON.stringify(valueFromApi) === JSON.stringify(valueFromConst));
      }) ?? []
  };

  const handleSubmit = useCallback((values: FormikSubmitValues) => updateReport(id || '', values), [id]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Paper fullSize>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Loader isLoading={isLoading}>
          <ReportForm inEditMode initialValues={valuesForForm} submitHandler={handleSubmit} />
        </Loader>
      </Paper>
    </Box>
  );
};

export default EditReport;
