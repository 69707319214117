import { ApiResponse } from 'api/models';
import { AxiosPromise } from 'axios';

export const downloadXlsFile = async (callback: AxiosPromise<ApiResponse<string>>) => {
  const getData = await callback;
  const blob = new Blob([getData.data as unknown as BlobPart], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);

  let fileName = 'undefined.xlsx';
  const headerFileNameMatch = getData.headers['content-disposition'].match(/filename="([^"]+)"/);
  if (headerFileNameMatch == null) {
    console.error('Could not find file name in content-disposition reponse header.');
  } else {
    fileName = headerFileNameMatch[1];
  }

  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};
