import { ApiError } from 'api/models';

export type ErrorsType = {
  ErrorId: string;
  Errors: ApiError[];
};

export enum ERRORS {
  ERROR_UNEXPECTED = 'ERROR_UNEXPECTED_ERROR_OCCURRED',
  ERROR_FORMIK_SUBMIT_FAILED = 'ERROR_FORMIK_SUBMIT_FAILED',
  ERROR_NO_MATCHING_STATE_FOUND = 'No matching state found in storage'
}
